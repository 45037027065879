<template>
  <v-row>
    <v-col cols="12">
      <toolbar v-if="toolbarVisibility && type !== 'task-view'" :employee.sync="selectedEmployee"
        :service-type.sync="selectedServiceType" :search-query.sync="searchQuery"
        @calendar-toolbar:hide="toolbarVisibility = false" @search="getTaskData(true)" />
    </v-col>
    <v-col cols="12" class="py-0">
      <v-row align="center" no-gutters>
        <v-col v-if="type === 'month'" cols="auto" class="mr-4">
          <v-btn-toggle class="btn-toggle-employee" v-model="employeeToggleButton">
            <v-btn small min-width="20" min-height="20" value="employee-row" class="px-2">
              空
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="auto">
          <v-btn-toggle dense class="btn-toggle-worker-type" v-model="workerTypeToggleButton" mandatory
            @change="getTaskData(false, true)">
            <v-btn height="28" :value="0">
              一般
            </v-btn>

            <v-btn height="28" :value="1">
              日常清掃員
            </v-btn>

            <v-btn height="28" :value="2">
              協力会社
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col v-if="type === 'task-view'" cols="3" class="col-sm-5 col-lg-4 pl-4">
          <v-select dense class="select" outlined hide-details :prepend-inner-icon="sortIcon" :items="sortSelectItems"
            item-value="name" item-text="text" v-model="selectedSort" @change="getTaskData(false, true)">
          </v-select>
        </v-col>

        <v-spacer class="d-sm-none d-lg-flex"></v-spacer>
        <v-col cols="auto" class="mr-12 " v-if="type !== 'task-view'">
          <v-btn height="28" text color="#a5a5a5" @click="toggleToolbar()">
            条件検索
          </v-btn>
        </v-col>
        <v-col cols="auto" class="mr-8" v-if="type !== 'task-view'">
          <v-btn height="28" class="btn-new-task" color="#CFD3FE" depressed min-width="100" to="/task/create">
            <v-icon left>
              mdi-plus
            </v-icon>
            タスク
          </v-btn>
        </v-col>
        <template v-if="navigation">
          <v-col cols="auto" class="mr-4 mr-sm-2">
            <v-btn color="#777777" text height="28" class="btn-calendar-navigation" min-width="8" @click="previous()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn color="#777777" text height="28" class="btn-calendar-navigation" min-width="8" @click="next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <div class="month-name text-right" :class="type">
            {{ monthName }}
          </div>
        </template>
      </v-row>
    </v-col>

    <v-col cols="12">
      <template v-if="type === 'month'">
        <monthly-calendar ref="monthlyCalendar" :month="_month"
          :hide-employee-row="employeeToggleButton !== 'employee-row'" :unassigned-employees="unassignedEmployees"
          :loading="loading" @event:click="goToTaskDetail"></monthly-calendar>
      </template>

      <template v-else-if="type === 'three-days'">
        <three-days-calendar ref="three-days-calendar" :day="_month"
          :hide-employee-row="employeeToggleButton !== 'employee-row'" :unassigned-employees="unassignedEmployees"
          :loading="loading" @event:click="!loading ? $emit('event:click', $event) : null"></three-days-calendar>
      </template>

      <template v-else-if="type === 'task-view'">
        <task-view-calendar ref="task-view-calendar" :partner-mode="workerTypeToggleButton === 2" :month="_month"
          :unassigned-employees="unassignedEmployees" :events="allTasks" :loading="loading"
          @event:click="goToTaskDetail" />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from '@/plugins/dayjs'
const MonthlyCalendar = () =>
  import(
    /* webpackChunkName: "type-calendar-monthly" */ '@/components/admin/partials/Calendar/Type/Monthly'
  )
const ThreeDaysCalendar = () =>
  import(
    /* webpackChunkName: "type-calendar-three-days" */ '@/components/admin/partials/Calendar/Type/ThreeDays'
  )
const TaskViewCalendar = () =>
  import(
    /* webpackChunkName: "type-calendar-task-view" */ '@/components/admin/partials/Calendar/Type/TaskView'
  )
import Toolbar from '@/components/admin/partials/Calendar/Toolbar'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'Calendar',
  components: { TaskViewCalendar, Toolbar, ThreeDaysCalendar, MonthlyCalendar },
  props: {
    month: {
      type: Object,
      default: dayjs(),
      required: true
    },
    type: {
      type: String,
      default: 'month',
      required: false
    },
    navigation: {
      type: Boolean,
      default: true,
      required: false
    },
    workerTypeToggle: {
      type: Boolean,
      default: true,
      required: false
    },
    unassignedEmployees: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  mounted() {
    if (this.type === 'task-view') {
      this.initTaskViewPeriod()
    }
    this.getTaskData(false, true)
  },
  computed: {
    ...mapGetters(['allTasks']),
    _month: {
      get: function () {
        return this.month
      },
      set: function (newValue) {
        this.$emit('update:month', newValue)
      }
    },
    monthName() {
      if (this.type === 'month') return this._month.format('YYYY年 MMMM')

      if (this.type === 'three-days') {
        let startDate = this._month.subtract(1, 'day').format('M/D')
        let endDate = this._month.add(1, 'day').format('M/D')
        return `${startDate} ~ ${endDate}`
      }

      if (this.type === 'task-view') {
        let month = this._month.format('YYYY年M月')
        let startWeek = this._month.startOf('week').format('M/D')
        let endWeek = this._month.endOf('week').format('M/D')
        return `${month} : ${startWeek}~${endWeek}`
      }

      return ''
    },
    unit() {
      if (this.type === 'month') return 'month'
      if (this.type === 'task-view') return 'week'
      return 'day'
    },
    unitAmount() {
      if (this.type === 'three-days') return 3
      return 1
    },
    sortIcon() {
      if (this.selectedSort === 'most') return '$sortDown'
      else if (this.selectedSort === 'least') return '$sortUp'
      return ''
    }
  },
  data() {
    return {
      employeeToggleButton: '',
      workerTypeToggleButton: 0,
      toolbarVisibility: false,
      loading: false,
      currentPeriod: {
        startDate: null,
        endDate: null
      },
      selectedEmployee: [],
      selectedServiceType: [],
      searchQuery: '',
      selectedSort: 'display_order',
      sortSelectItems: [
        {
          name: 'display_order',
          text: '従業員表示順'
        },
        {
          name: 'most',
          text: '月単位の合計タスク多い順'
        },
        {
          name: 'least',
          text: '月単位の合計タスク少ない順'
        }
      ]
    }
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading
    },

    next() {
      this._month = this._month.add(1, this.unit)
      this.getTaskData()
    },

    previous() {
      this._month = this._month.subtract(1, this.unit)
      this.getTaskData()
    },

    toggleToolbar() {
      this.toolbarVisibility = !this.toolbarVisibility
    },

    goToTaskDetail(e) {
      this.$router.push({
        name: 'TaskView',
        params: {
          id: e
        }
      })
    },

    initTaskViewPeriod() {
      let startDate, endDate
      this._month = this._month.startOf('week')
      if (this._month.date() < 21) {
        startDate = this._month
          .subtract(3, 'week')
          .date(21)
          .startOf('week')
          .add(1, 'week')
        // endDate = this._month.date(20).endOf('week')
        endDate = this._month.endOf('week')
      } else {
        startDate = this._month
          .date(21)
          .startOf('week')
          .add(1, 'week')
        endDate = this._month
          // .add(1, 'month')
          // .date(20)
          // .endOf('week')
          .date(21)
          .startOf('week')
          .add(3, 'week')
      }
      this.currentPeriod.startDate = startDate
      this.currentPeriod.endDate = endDate
    },

    getTaskData: debounce(async function (search = false, force = false) {
      if (this.type === 'task-view' && !force) {
        if (
          this._month
            .startOf('week')
            .isBetween(
              this.currentPeriod.startDate,
              this.currentPeriod.endDate,
              null,
              '[]'
            )
          // .isBefore(this.currentPeriod.endDate)
          && !this._month
            .startOf('week').isSame(this.currentPeriod.endDate)
        ) {
          return
        }
      }
      this.loading = true
      let startDate
      let endDate

      if (this.type === 'month') {
        startDate = this._month
          .startOf('month')
          .startOf('week')
          .format('YYYY-MM-DD')

        endDate = this._month
          .endOf('month')
          .endOf('week')
          .format('YYYY-MM-DD')
      } else if (this.type === 'task-view') {
        this.initTaskViewPeriod()
        startDate = this.currentPeriod.startDate.date(21).format('YYYY-MM-DD')
        endDate = this.currentPeriod.endDate.format('YYYY-MM-DD')
      } else {
        startDate = this._month
          .subtract(this.unitAmount, 'day')
          .format('YYYY-MM-DD')
        endDate = this._month.add(this.unitAmount, 'day').format('YYYY-MM-DD')
      }

      let params = {
        date_from: startDate,
        date_to: endDate,
        employee_type: this.workerTypeToggleButton
      }

      if (search) {
        params.user_id = [...this.selectedEmployee]
        params.service_type_id = [...this.selectedServiceType]
        params.name = this.searchQuery
      }

      if (this.type === 'task-view' && this.selectedSort) {
        params.sort_by = this.selectedSort
      }

      if (this.type !== 'task-view')
        await this.$store.dispatch('GET_ALL_TASK_BY_DATE_RANGE', params)

      if (this.workerTypeToggleButton === 2)
        await this.$store.dispatch('PARTNER_GET_ALL_WITH_TASKS', params)
      else await this.$store.dispatch('USER_GET_ALL_WITH_TASKS', params)

      if (this.type === 'month') this.$refs.monthlyCalendar.initEvents()
      this.loading = false
    }, 1000)
  }
}
</script>

<style lang="scss" src="./Calendar.scss" scoped>
</style>
