<template>
  <v-container class="px-8 toolbar-container">
    <v-row style="min-height: 50px" class="text-center" align="center">
      <v-col cols="3">
        <v-select
          class="select"
          color="#2F80ED"
          placeholder="従業員"
          outlined
          dense
          hide-details
          :items="allUsers"
          v-model="_employee"
          item-value="id"
          item-text="full_name"
          multiple
          chips
          clearable
          @click:clear="search"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          class="select"
          color="#2F80ED"
          placeholder="作業内容"
          outlined
          dense
          hide-details
          :items="allServices"
          v-model="_serviceType"
          item-value="id"
          item-text="name"
          multiple
          chips
          clearable
          :loading="loading"
          @click:clear="search"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="select"
          color="#2F80ED"
          placeholder="顧客名 / 現場名"
          outlined
          dense
          hide-details
          v-model="_searchQuery"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn color="#a5a5a5" block text @click="clear()">
          クリア
        </v-btn>
      </v-col>
      <v-col cols="auto" class="flex-grow-1">
        <v-btn
          color="#2F80ED"
          block
          depressed
          class="btn-search"
          @click="search()"
        >
          <v-icon left color="white">
            mdi-magnify
          </v-icon>
          検索
        </v-btn>
      </v-col>
      <v-col cols="12" lg="auto">
        <v-btn
          fab
          x-small
          depressed
          height="20"
          width="20"
          color="#E27C7C"
          @click="hideSelf()"
        >
          <v-icon color="white" size="16">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Toolbar',
  props: {
    employee: {
      type: Array,
      default: () => [],
      required: true
    },
    serviceType: {
      type: Array,
      default: () => [],
      required: true
    },
    searchQuery: {
      type: String,
      default: '',
      required: true
    }
  },
  computed: {
    ...mapGetters(['allUsers', 'allServices']),
    _employee: {
      get() {
        return this.employee
      },
      set(newValue) {
        return this.$emit('update:employee', newValue)
      }
    },

    _serviceType: {
      get() {
        return this.serviceType
      },
      set(newValue) {
        return this.$emit('update:service-type', newValue)
      }
    },

    _searchQuery: {
      get() {
        return this.searchQuery
      },
      set(newValue) {
        return this.$emit('update:search-query', newValue)
      }
    }
  },
  data() {
    return {
      loading: false
    }
  },

  created() {
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      let params = {}
      params.paginate = 10
      await this.$store.dispatch('SERVICE_GET_ALL', params)
      this.loading = false
    },
    hideSelf() {
      this.$emit('calendar-toolbar:hide')
    },
    clear() {
      if (
        this.employee !== '' ||
        this.serviceType !== '' ||
        this.searchQuery !== ''
      ) {
        this._employee = ''
        this._serviceType = ''
        this._searchQuery = ''
        this.search()
      }
    },
    search() {
      this.$emit('search')
    }
  }
}
</script>

<style src="./Toolbar.scss" lang="scss" scoped></style>
